<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <h2 class="title">
        <div class="title_icon"></div>
        添加/修改功能
      </h2>
      <!--搜索条件区域-->
      <div class="searchWrapper">
        <el-form
          label-position="right"
          label-width="100px"
          :model="formInline"
          style="width: 440px"
          :rules="rules"
          :ref="'form'"
        >
          <el-form-item label="当前编辑角色">
            {{ systemRoleName }}
          </el-form-item>
          <el-form-item label="操作菜单">
            <el-tree
              :data="data2"
              show-checkbox
              node-key="code"
              :check-strictly="false"
              ref="tree"
              :props="defaultProps"
            >
            </el-tree>
          </el-form-item>
        </el-form>
        <div style="text-align: center; margin-top: 20px">
          <el-button type="primary" @click="submitData" style="width: 88px">提交</el-button>
          <el-button type="" @click="$router.go(-1)" style="width: 88px">取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import {
// 	sortMenu
// } from '@/common/js/public.js'
export default {
  name: "hello",
  data() {
    return {
      systemRoleName: "",
      data2: [
        {
          id: 1,
          label: "一级 1",
          children: [
            {
              id: 4,
              label: "二级 1-1",
              children: [
                {
                  id: 9,
                  label: "三级 1-1-1",
                },
                {
                  id: 10,
                  label: "三级 1-1-2",
                },
              ],
            },
          ],
        },
        {
          id: 2,
          label: "一级 2",
          children: [
            {
              id: 5,
              label: "二级 2-1",
            },
            {
              id: 6,
              label: "二级 2-2",
            },
          ],
        },
        {
          id: 3,
          label: "一级 3",
          children: [
            {
              id: 7,
              label: "二级 3-1",
            },
            {
              id: 8,
              label: "二级 3-2",
            },
          ],
        },
      ],
      defaultProps: {
        children: "childs",
        label: "desc",
      },
      disabled: false,
      selectedOptions: [],
      options: [],
      cityList: [],
      isEdit: false,
      parentList: [],
      formInline: {
        areaCode: "",
        areaLevel: "2",
        areaName: "",
        areaType: "0",
        cityName: "",
        description: "",
        parentId: "",
        city: [],
        areaId: "",
      },
      rules: {
        areaLevel: [
          {
            required: true,
            message: "请选择区域级别",
            trigger: "blur",
          },
        ],
        parentId: [
          {
            required: true,
            message: "请选择上级区域",
            trigger: "blur",
          },
        ],
        areaName: [
          {
            required: true,
            message: "请输入区域名称",
            trigger: "blur",
          },
        ],
        areaCode: [
          {
            required: true,
            message: "请输入区域编码",
            trigger: "blur",
          },
        ],
        city: [
          {
            type: "array",
            required: true,
            message: "请选择城市名称",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    sortMenu(res) {
      let arr = [];
      res.forEach((value) => {
        value.childs = [];
        if (value.parentCode == 0) {
          arr.push(value);
        }
      });
      function getChild(data) {
        data.forEach((value) => {
          value.childs = res.filter((child) => {
            return child.parentCode == value.code;
          });
          getChild(value.childs);
        });
      }
      getChild(arr);
      return arr;
    },
    getSystem() {
      this.$axios.get("/acb/2.0/systems/loginUser/superviseResources").then((res) => {
        if (res.state == 0) {
          this.data2 = this.sortMenu(res.value);
          if (this.$route.query.resourceIds) {
            let arr = this.$route.query.resourceIds.split(",");
            for (let i = 0; i < this.data2.length; i++) {
              for (let k = 0; k < arr.length; k++) {
                if (this.data2[i].code == arr[k]) {
                  if (this.data2[i].parentCode == 0) {
                    arr.splice(k, "1");
                  }
                }
              }
            }
            this.$refs.tree.setCheckedKeys(arr, true);
          }
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    getRole(systemRoleId) {
      //	this.$refs.tree.setCheckedKeys(res.value.resourceIds.split(','))
    },
    submitData() {
      if (this.$refs.tree.getCheckedKeys(true).length < 1) {
        this.$alert("未选择权限", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      if (this.$route.query.monitorManagerRoleId) {
        this.$route.query.resourceIds = this.$refs.tree.getCheckedKeys(true).toString();
        this.$axios
          .post("/acb/2.0/monitorManagerRole/update", {
            data: this.$route.query,
          })
          .then((res) => {
            if (res.state == 0) {
              this.$message({
                type: "info",
                message: "操作成功",
              });
              sessionStorage.resourceIds = "";
              this.$router.go(-1);
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          });
      } else {
        sessionStorage.resourceIds = this.$refs.tree.getCheckedKeys(true).toString();
        this.$router.go(-1);
      }
    },
  },
  components: {},
  created() {
    this.getSystem();
    if (this.$route.query.monitorManagerRoleId) {
      this.getRole(this.$route.query.monitorManagerRoleId);
      this.systemRoleName = this.$route.query.monitorManagerRoleName;
    }
  },
  mounted() {},
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
	height: 35px;
.content
	background: #FFFFFF;
	overflow: hidden;
	border: 1px solid #C0CCDA;
	border-radius: 4px;
	padding: 20px;
</style>
